import styled from "styled-components";
import NoSearchResultIcon from "../../icon/NoSearchResultIcon";
import EmptySearchBox from "./EmptySearchBox";
import SearchLoadingIcon from "../../icon/SearchLoadingIcon";
import SearchResultItem from "./SearchResultItem";
import { useState } from "react";

export default function SearchList({
  status,
  results,
}: {
  status: "default" | "loading" | "results";
  results: string[][];
}) {
  const [clickedCategory, setClickedCategory] = useState<
    "eLearning" | "studyResource"
  >("eLearning");
  const eLearningResults = results.filter((result) =>
    result[0].startsWith("eLearning")
  );
  const studyResourceResults = results.filter(
    (result) => !result[0].startsWith("eLearning")
  );

  if (status === "default") {
    return (
      <SearchListContainer type="default">
        <EmptySearchBox
          image={<NoSearchResultIcon />}
          title="단어 및 내용을 검색하세요."
          text="관련 무엇을 바로 찾을 수 있어요."
        />
      </SearchListContainer>
    );
  }

  if (status === "loading") {
    return (
      <SearchListContainer type="loading">
        <EmptySearchBox
          image={<SearchLoadingIcon />}
          title="검색 중..."
          text="검색 결과를 불러오고 있어요."
        />
      </SearchListContainer>
    );
  }

  return (
    <SearchListContainer type="results">
      <CategoryTabContainer>
        <ContentCategoryTab
          onClick={() => setClickedCategory("eLearning")}
          isClicked={clickedCategory === "eLearning"}
        >
          이러닝
        </ContentCategoryTab>
        <ContentCategoryTab
          onClick={() => setClickedCategory("studyResource")}
          isClicked={clickedCategory === "studyResource"}
        >
          학습자원
        </ContentCategoryTab>
      </CategoryTabContainer>
      <ResultItemContainer>
        {clickedCategory === "eLearning"
          ? eLearningResults.map((result, index) => {
              return (
                <>
                  <SearchResultItem result={result} key={index} />
                  {index !== eLearningResults.length - 1 && <Divider />}
                </>
              );
            })
          : studyResourceResults.map((result, index) => {
              return (
                <>
                  <SearchResultItem result={result} key={index} />
                  {index !== studyResourceResults.length - 1 && <Divider />}
                </>
              );
            })}
      </ResultItemContainer>
    </SearchListContainer>
  );
}

type SearchListContainerProps = {
  type: "default" | "loading" | "results";
};

type ContentCategoryTabProps = {
  isClicked: boolean;
};

const SearchListContainer = styled.div<SearchListContainerProps>`
  display: flex;
  height: calc(100% - 80px);
  padding: ${(props) =>
    props.type === "results" ? "0px 40px 15px 40px" : "0px 28px"};

  flex-direction: column;
  justify-content: ${(props) =>
    props.type === "results" ? "flex-start" : "center"};
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  background: rgba(132, 132, 156, 0.1);
`;

const ResultItemContainer = styled.div`
  height: calc(100% - 80px);
  overflow: auto;
`;

const CategoryTabContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 18px;
  padding-top: 24px;
  padding-bottom: 26px;
`;

const ContentCategoryTab = styled.div<ContentCategoryTabProps>`
  display: flex;
  color: ${(props) => (props.isClicked ? "#fff" : "#84849C")};

  /* Basic/20B */
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: ${(props) => (props.isClicked ? "700" : "500")};
  line-height: 28px; /* 140% */

  border-bottom: ${(props) => (props.isClicked ? "2px solid white" : "none")};

  cursor: pointer;
`;

const Divider = styled.div`
  width: 100%;
  border: 0.5px solid var(--gray-bg, #31313b);
  margin: 26px 0px;
`;
