import React from "react";
import styled from "styled-components";
import SearchIcon from "../icon/SearchIcon";

export default function SearchInput({
  inputValue,
  handleInputChange,
}: {
  inputValue: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <SearchInputWrapper>
      <SearchIcon />
      <SearchInputContainer
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="검색어를 입력해 주세요."
      />
    </SearchInputWrapper>
  );
}

const SearchInputWrapper = styled.div`
  display: flex;
  height: 56px;
  padding: 0px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: var(--background, #13131e);
`;

const SearchInputContainer = styled.input`
  background: var(--background, #13131e);
  width: 100%;

  border: none; // 기본 테두리 제거
  -webkit-appearance: none; // Safari/Chrome 기본 스타일 제거
  -moz-appearance: none; // Firefox 기본 스타일 제거
  appearance: none; // 표준 기본 스타일 제거

  /* Basic/18M */
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px; /* 150% */
  color: var(--SGray-Colors-white, #fff);

  &::placeholder {
    color: var(--text-gray, #84849c);
  }

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
`;
