import { keywordNodesData, thirdNodesData } from "../data/nodeData";

export const filterChildNodes = (nodeId: number) => {
  const filteredChildNodes = thirdNodesData.filter((node) => {
    const id = Number(node.id);

    const isChildNode =
      id % nodeId < 10 && id - nodeId * 10 > 0 && id - nodeId * 10 < 10;

    return isChildNode;
  });

  const filteredKeywordNodes = keywordNodesData.filter((node) => {
    const id = Math.floor(Number(node.id?.toString().split("_")[0]) / 10);

    return id && Number(id) === nodeId;
  });

  return [...filteredChildNodes, ...filteredKeywordNodes];
};

export const getSelectedEndNodeLabel = (selectedNodeId: number) => {
  return keywordNodesData.find((node) => node.id === selectedNodeId)?.label;
};
