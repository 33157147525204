import React, { useState } from "react";
import styled from "styled-components";
import MoreIcon from "../../icon/MoreIcon";
import CloseIcon from "../../icon/CloseIcon";

export default function SearchResultItem({ result }: { result: string[] }) {
  const [
    resultId,
    resultTitle,
    resultSummary,
    resultDate,
    resultCategory1,
    resultCategory2,
    resultCategory3,
    resultURL,
  ] = result;
  const [showAllContent, setShowAllContent] = useState(false);

  const handleResultClick = (result: string[]) => {
    if (resultURL) {
      window.open(resultURL);
    }
  };

  return (
    <SearchResultItemContainer onClick={() => handleResultClick(result)}>
      <SearchResultCategoryContainer>
        {[resultCategory1, resultCategory2, resultCategory3].map(
          (category, index) => {
            return (
              <SearchResultCategory
                key={index}
              >{`#${category}`}</SearchResultCategory>
            );
          }
        )}
      </SearchResultCategoryContainer>
      <SearchResultTitle>{resultTitle}</SearchResultTitle>
      <SearchResultSummary showAllContent={showAllContent}>
        {resultSummary}
      </SearchResultSummary>
      <MoreIconContainer
        onClick={(event) => {
          event.stopPropagation();
          setShowAllContent(!showAllContent);
        }}
      >
        {showAllContent ? <CloseIcon /> : <MoreIcon />}
      </MoreIconContainer>
      {resultDate && <SearchResultDate>{resultDate}</SearchResultDate>}
    </SearchResultItemContainer>
  );
}

interface SearchResultSummaryProps {
  showAllContent: boolean;
}

const SearchResultItemContainer = styled.li`
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
`;

const SearchResultCategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const SearchResultCategory = styled.p`
  margin: 0;
  color: #d66f51;

  /* Basic/15M */
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px; /* 153.333% */
`;

const SearchResultTitle = styled.p`
  color: var(--SGray-Colors-white, #fff);
  font-feature-settings: "liga" off, "clig" off;

  /* Basic/20B */
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
  margin: 0;
`;

const SearchResultSummary = styled.p<SearchResultSummaryProps>`
  color: var(--text-white, #d7d7d8);

  /* Basic/15R */
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 153.333% */
  margin: 0;

  ${({ showAllContent }) =>
    !showAllContent &&
    `
      display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4; /* 원하는 줄 수 설정 */
  `}
`;

const SearchResultDate = styled.p`
  margin: 0;
  margin-top: 4px;
  color: var(--text-gray, #84849c);

  /* Basic/15R */
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 153.333% */
`;

const MoreIconContainer = styled.div`
  display: flex;
  margin: 0;
  margin-top: 4px;
  justify-content: center;
  cursor: pointer;
`;
