import React from "react";
import styled from "styled-components";

export default function EmptySearchBox({
  image,
  title,
  text,
}: {
  image: React.ReactNode;
  title: string;
  text: string;
}) {
  return (
    <EmptySearchBoxContainer>
      {image}
      <EmptySearchBoxTitleContainer>
        <EmptySearchBoxTitle>{title}</EmptySearchBoxTitle>
        <EmptySearchBoxText>{text}</EmptySearchBoxText>
      </EmptySearchBoxTitleContainer>
    </EmptySearchBoxContainer>
  );
}

const EmptySearchBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const EmptySearchBoxTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const EmptySearchBoxTitle = styled.p`
  color: var(--white, #d1d1d1);
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;

  /* Basic/17B */
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 152.941% */
  margin: 0;
`;

const EmptySearchBoxText = styled.p`
  color: var(--white, #d1d1d1);
  text-align: center;

  /* Basic/14R */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin: 0;
`;
