import { Edge } from "vis-network";
import { childNodesData, nodesData } from "./nodeData";

export const edgesData: Edge[] = [
  // 보건복지공통교육(id:1)의 연결
  { id: 1, from: 1, to: 11 }, // 기본역량
  { id: 2, from: 1, to: 12 }, // 공통역량
  { id: 3, from: 1, to: 13 }, // 디지털
  { id: 4, from: 1, to: 14 }, // 인문교양

  // 사회복지교육(id:2)의 연결
  { id: 5, from: 2, to: 21 }, // 복지일반
  { id: 6, from: 2, to: 22 }, // 복지행정
  { id: 7, from: 2, to: 23 }, // 사회서비스
  { id: 8, from: 2, to: 24 }, // 노인복지
  { id: 9, from: 2, to: 25 }, // 장애인복지
  { id: 10, from: 2, to: 26 }, // 아동복지

  // 보건교육(id:3)의 연결
  { id: 11, from: 3, to: 31 }, // 보건일반
  { id: 12, from: 3, to: 32 }, // 건강증진
  { id: 13, from: 3, to: 33 }, // 정신건강
  { id: 14, from: 3, to: 34 }, // 감염병일반
  { id: 15, from: 3, to: 35 }, // 감염병진단분석·생물안전
  { id: 16, from: 3, to: 36 }, // 식의약일반
  { id: 17, from: 3, to: 37 }, // 식품
  { id: 18, from: 3, to: 38 }, // 의약품안전

  // 바이오헬스교육(id:4)의 연결
  { id: 20, from: 4, to: 42 }, // 의료서비스
  { id: 21, from: 4, to: 43 }, // 제약
  { id: 22, from: 4, to: 44 }, // 화장품
  { id: 23, from: 4, to: 45 }, // 의료기기
  { id: 24, from: 4, to: 46 }, // 보건의료데이터·AI

  // 국제협력교육(id:5)의 연결
  { id: 25, from: 5, to: 51 }, // 국제개발협력(ODA)
  { id: 26, from: 5, to: 52 }, // 글로벌헬스케어
];
export const childEdgesData: Edge[] = [
  // 보건복지공통교육(id:1) Level2->Level3 연결
  { id: 27, from: 11, to: 111 }, // 기본역량 -> 신규자
  { id: 28, from: 12, to: 121 }, // 공통역량 -> 직무역량
  { id: 29, from: 12, to: 122 }, // 공통역량 -> 관계역량
  { id: 30, from: 12, to: 123 }, // 공통역량 -> 소진예방
  { id: 31, from: 12, to: 124 }, // 공통역량 -> 리더십
  { id: 32, from: 13, to: 131 }, // 디지털 -> OA실무
  { id: 33, from: 14, to: 141 }, // 인문교양 -> 인문학
  { id: 34, from: 14, to: 142 }, // 인문교양 -> 건강관리
  { id: 35, from: 14, to: 143 }, // 인문교양 -> 역사

  // 사회복지교육(id:2) Level2->Level3 연결
  { id: 36, from: 21, to: 211 }, // 복지일반 -> 사회복지법제
  { id: 37, from: 21, to: 212 }, // 복지일반 -> 사회복지정책
  { id: 38, from: 21, to: 213 }, // 복지일반 -> 사회복지 가치와 인권
  { id: 39, from: 21, to: 214 }, // 복지일반 -> 사업관리
  { id: 40, from: 22, to: 221 }, // 복지행정 -> 공공부조
  { id: 41, from: 22, to: 222 }, // 복지행정 -> 지역복지
  { id: 42, from: 22, to: 223 }, // 복지행정 -> 통합사례관리
  { id: 43, from: 23, to: 231 }, // 사회서비스 -> 복지부정수급예방
  { id: 44, from: 23, to: 232 }, // 사회서비스 -> 사회복지법인및시설관리
  { id: 45, from: 24, to: 241 }, // 노인복지 -> 노인복지일반
  { id: 46, from: 24, to: 242 }, // 노인복지 -> 노인의료돌봄통합지원
  { id: 47, from: 24, to: 243 }, // 노인복지 -> 노인인권
  { id: 48, from: 24, to: 244 }, // 노인복지 -> 장기요양
  { id: 49, from: 25, to: 251 }, // 장애인복지 -> 장애인복지일반
  { id: 50, from: 25, to: 252 }, // 장애인복지 -> 장애인인권
  { id: 51, from: 26, to: 261 }, // 아동복지 -> 아동권리
  { id: 52, from: 26, to: 262 }, // 아동복지 -> 아동돌봄
  { id: 53, from: 26, to: 263 }, // 아동복지 -> 아동보호
  { id: 54, from: 26, to: 264 }, // 아동복지 -> 아동안전·건강
  { id: 55, from: 26, to: 265 }, // 아동복지 -> 아동청년복지일반

  // 보건교육(id:3) Level2->Level3 연결
  { id: 56, from: 31, to: 311 }, // 보건일반 -> 보건의료 정책
  { id: 57, from: 31, to: 312 }, // 보건일반 -> 의료급여
  { id: 58, from: 31, to: 313 }, // 보건일반 -> 지역보건
  { id: 59, from: 31, to: 314 }, // 보건일반 -> 치매관리
  { id: 60, from: 32, to: 321 }, // 건강증진 -> 건강생활 실천
  { id: 61, from: 32, to: 322 }, // 건강증진 -> 디지털헬스
  { id: 62, from: 32, to: 323 }, // 건강증진 -> 비감염성질환예방관리
  { id: 63, from: 32, to: 324 }, // 건강증진 -> 인구집단별 건강관리
  { id: 64, from: 32, to: 325 }, // 건강증진 -> 지역사회 건강증진
  { id: 65, from: 32, to: 326 }, // 건강증진 -> 건강생활 실천
  { id: 66, from: 33, to: 331 }, // 정신건강 -> 중독
  { id: 67, from: 33, to: 332 }, // 정신건강 -> 자살예방·생명존중
  { id: 68, from: 33, to: 333 }, // 정신건강 -> 정신인권
  { id: 69, from: 33, to: 334 }, // 정신건강 -> 지역사회 정신건강
  { id: 70, from: 34, to: 341 }, // 감염병일반 -> 감염병 위기대응
  { id: 71, from: 34, to: 342 }, // 감염병일반 -> 감염병 정책·관리예방
  { id: 72, from: 35, to: 351 }, // 감염병진단분석·생물안전 -> 의료감염관리
  { id: 73, from: 35, to: 352 }, // 감염병진단분석·생물안전 -> 생물안전
  { id: 74, from: 36, to: 361 }, // 식의약일반 -> 식의약정책
  { id: 75, from: 37, to: 371 }, // 식품 -> 식품안전
  { id: 76, from: 38, to: 381 }, // 의약품안전 -> 의약품

  // 바이오헬스교육(id:4) Level2->Level3 연결
  { id: 78, from: 42, to: 421 }, // 의료서비스 -> 의료서비스일반
  { id: 79, from: 43, to: 431 }, // 제약 -> 마케팅
  { id: 80, from: 43, to: 432 }, // 제약 -> 생산
  { id: 81, from: 43, to: 433 }, // 제약 -> 연구개발
  { id: 82, from: 43, to: 434 }, // 제약 -> 인허가
  { id: 83, from: 43, to: 435 }, // 제약 -> 품질
  { id: 84, from: 44, to: 441 }, // 화장품 -> 마케팅
  { id: 85, from: 44, to: 442 }, // 화장품 -> 생산
  { id: 86, from: 44, to: 443 }, // 화장품 -> 연구개발
  { id: 87, from: 44, to: 444 }, // 화장품 -> 인허가
  { id: 88, from: 46, to: 461 }, // 보건의료데이터·AI -> 보건의료데이터·AI
  { id: 89, from: 45, to: 451 }, // 보건의료데이터·AI -> 마케팅
  { id: 90, from: 45, to: 452 }, // 보건의료데이터·AI -> 생산
  { id: 91, from: 45, to: 453 }, // 보건의료데이터·AI -> 인허가

  // 국제협력교육(id:5) Level2->Level3 연결
  { id: 92, from: 51, to: 511 }, // 국제개발협력(ODA) -> 감염병
  { id: 93, from: 51, to: 512 }, // 국제개발협력(ODA) -> 국제개발협력
  { id: 94, from: 51, to: 513 }, // 국제개발협력(ODA) -> 일차보건의료
  { id: 95, from: 52, to: 521 }, // 글로벌헬스케어 -> 의료통역
  { id: 96, from: 52, to: 522 }, // 글로벌헬스케어 -> 의료해외진출
];

const categories_with_selected_keywords = require("../data/categories_with_selected_keywords.json");

export const keywordEdgesData: Edge[] = Object.entries(
  categories_with_selected_keywords
).flatMap(([level1Key, level1Value]: any) => {
  // level1의 id 찾기 (1~5)
  const level1Id = nodesData.find((node) => node.label === level1Key)?.id;

  return Object.entries(level1Value).flatMap(
    ([level2Key, level2Value]: any) => {
      // level2의 id 찾기 (11~52)
      const level2Node = nodesData.find(
        (node) =>
          node.label === level2Key &&
          Math.floor(Number(node.id) / 10) === level1Id
      );

      return Object.entries(level2Value).flatMap(
        ([level3Key, keywords]: any) => {
          // level3의 id 찾기 (111~522)
          const level3Id = childNodesData.find(
            (node) =>
              node.label === level3Key &&
              Math.floor(Number(node.id) / 10) === level2Node?.id
          )?.id;

          // 각 키워드에 대한 edge 생성
          return keywords.map((keyword: string, index: number) => ({
            from: `${level3Id}_${index + 1}`,
            to: level3Id,
          }));
        }
      );
    }
  );
});
