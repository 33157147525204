import React from "react";

export default function HomeDefaultIcon() {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_14_317)">
        <circle cx="26" cy="26" r="26" fill="url(#paint0_linear_14_317)" />
      </g>
      <g clipPath="url(#clip0_14_317)">
        <path
          d="M38 24V36C38 36.2652 37.8946 36.5196 37.7071 36.7071C37.5196 36.8946 37.2652 37 37 37H30C29.7348 37 29.4804 36.8946 29.2929 36.7071C29.1054 36.5196 29 36.2652 29 36V29.5C29 29.3674 28.9473 29.2402 28.8536 29.1464C28.7598 29.0527 28.6326 29 28.5 29H23.5C23.3674 29 23.2402 29.0527 23.1464 29.1464C23.0527 29.2402 23 29.3674 23 29.5V36C23 36.2652 22.8946 36.5196 22.7071 36.7071C22.5196 36.8946 22.2652 37 22 37H15C14.7348 37 14.4804 36.8946 14.2929 36.7071C14.1054 36.5196 14 36.2652 14 36V24C14.0002 23.4696 14.2111 22.9611 14.5862 22.5862L24.5863 12.5862C24.9613 12.2114 25.4698 12.0009 26 12.0009C26.5302 12.0009 27.0387 12.2114 27.4137 12.5862L37.4137 22.5862C37.7889 22.9611 37.9998 23.4696 38 24Z"
          fill="#0E0F17"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_14_317"
          x="0"
          y="0"
          width="53"
          height="54"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_14_317"
          />
        </filter>
        <linearGradient
          id="paint0_linear_14_317"
          x1="11"
          y1="8.28734e-07"
          x2="40"
          y2="56"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.580949" stopColor="#FF4E1B" />
          <stop offset="1" stopColor="#FF8764" />
        </linearGradient>
        <clipPath id="clip0_14_317">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(10 9)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
