import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import SearchInput from "./SearchInput";
import SearchList from "./SearchList";

// const API_URL = "https://kohi-api-455994517201.asia-northeast3.run.app/api";
const API_URL = "https://services.slid.cc/kohi-api/api";

const Search: React.FC<{ selectedKeyword?: string }> = ({
  selectedKeyword,
}: {
  selectedKeyword?: string;
}) => {
  const [query, setQuery] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [results, setResults] = useState<string[][]>([["No results"]]);
  const [status, setStatus] = useState<"default" | "loading" | "results">(
    "default"
  );

  useEffect(() => {
    if (selectedKeyword) {
      setInputValue(selectedKeyword);
      debouncedSetQuery(selectedKeyword);
    }
  }, [selectedKeyword]);

  useEffect(() => {
    // ping the server to wake it up
    const apiUrl = `${API_URL}/ping`;
    fetch(apiUrl);
  }, []);

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (!query) {
        setStatus("default");
        return;
      }
      const endpoint = "/search/semantic";
      const response = await fetch(`${API_URL}${endpoint}?query=${query}`);
      const data = await response.json();
      if (!Array.isArray(data) || data.length === 0) {
        setStatus("default");
        setResults([["No results"]]);
        return;
      }
      const displayData = data?.map((item: any) => [
        item.id,
        item.title,
        item.second_summary,
        item.date,
        item.category1,
        item.category2,
        item.category3,
        item.url,
      ]);

      setStatus("results");
      setResults(displayData);
    };
    fetchSearchResults();
  }, [query]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setInputValue(searchTerm);
    debouncedSetQuery(searchTerm);
  };

  const debouncedSetQuery = useCallback(
    debounce((value: string) => {
      setStatus("loading");
      setQuery(value);
    }, 500),
    []
  );

  function debounce<T extends (...args: any[]) => void>(
    func: T,
    delay: number
  ): T {
    let timeoutId: NodeJS.Timeout;
    return function (this: any, ...args: Parameters<T>) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    } as T;
  }

  return (
    <SearchContainer>
      <SearchInput
        inputValue={inputValue}
        handleInputChange={handleInputChange}
      />
      <SearchList status={status} results={results} />
    </SearchContainer>
  );
};

export default Search;

const SearchContainer = styled.div`
  width: 440px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
