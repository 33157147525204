import React, { useState } from "react";
import NetworkGraph from "./components/Graph";
import Search from "./components/Search";
import styled from "styled-components";
import HomeDefaultIcon from "./icon/HomeDefaultIcon";
const App: React.FC = () => {
  const [clickHomeButton, setClickHomeButton] = useState<boolean>(false);
  const [selectedKeyword, setSelectedKeyword] = useState<string>("");
  return (
    <AppLayoutContainer>
      <HomeButton onClick={() => setClickHomeButton(true)}>
        <HomeDefaultIcon />
      </HomeButton>
      <Divider />
      <MainContainer>
        <Title>한국보건복지인재원 콘텐츠 마인드맵</Title>
        <NetworkGraph
          clickHomeButton={clickHomeButton}
          setClickHomeButton={setClickHomeButton}
          setSelectedKeyword={setSelectedKeyword}
        />
      </MainContainer>

      <Search selectedKeyword={selectedKeyword} />
    </AppLayoutContainer>
  );
};

export default App;

const AppLayoutContainer = styled.div`
  width: 100%;
  background-image: url("./assets/background-image.png");
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 40px;
  box-sizing: border-box;
`;

const HomeButton = styled.div`
  width: 52px;
  height: 52px;
  cursor: pointer;
`;

const Divider = styled.div`
  height: 100%;
  border: solid 1px var(--gray-bg, #31313b);
  margin-left: 28px;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 28px;
`;

const Title = styled.h1`
  color: #fff;
  margin: 0;
  font-family: Helvetica;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
`;
