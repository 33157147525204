export default function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M18.5714 28.5714C24.0943 28.5714 28.5714 24.0943 28.5714 18.5714C28.5714 13.0486 24.0943 8.57141 18.5714 8.57141C13.0486 8.57141 8.57141 13.0486 8.57141 18.5714C8.57141 24.0943 13.0486 28.5714 18.5714 28.5714Z"
        stroke="#84849C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7142 25.7143L31.4285 31.4286"
        stroke="#84849C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
