import React, { useEffect, useRef } from "react";
import { Network, Options } from "vis-network";
import { DataSet } from "vis-data";
import "vis-network/styles/vis-network.css";
import styled from "styled-components";
import { centerColor, nodesData, thirdNodesData } from "../data/nodeData";
import { childEdgesData, edgesData, keywordEdgesData } from "../data/edgeData";
import { filterChildNodes, getSelectedEndNodeLabel } from "../util/Graph";

const NetworkGraph: React.FC<{
  clickHomeButton: boolean;
  setClickHomeButton: Function;
  setSelectedKeyword: Function;
}> = ({
  clickHomeButton,
  setClickHomeButton,
  setSelectedKeyword,
}: {
  clickHomeButton: boolean;
  setClickHomeButton: Function;
  setSelectedKeyword: Function;
}) => {
  const networkContainer = useRef<HTMLDivElement | null>(null);
  const networkInstance = useRef<Network | null>(null);

  useEffect(() => {
    if (clickHomeButton) {
      setClickHomeButton(false);
    }
    if (networkContainer.current) {
      const newData = {
        nodes: nodesData,
        edges: edgesData,
      };

      const newOptions: Options = {
        autoResize: true,
        height: "100%",
        width: "100%",
        nodes: {
          borderWidth: 0,
          shape: "dot",
          font: {
            color: "#D7D7D8", // Node Label Text Color (White)
            size: 14,
            align: "center",
          },
          scaling: {
            min: 26,
            max: 65,
          },
        },

        edges: {
          color: { color: "#A0BFDD", opacity: 0.5 },
        },
        layout: {
          improvedLayout: true,
          clusterThreshold: 10,
        },
        interaction: {
          dragNodes: true,
          dragView: true,
          hover: true,
          selectable: true,
          tooltipDelay: 100,
          zoomView: true,
          zoomSpeed: 0.5,
        },
        physics: {
          enabled: true,
          stabilization: {
            enabled: true,
            iterations: 1000,
          },
          repulsion: {
            centralGravity: 0.1,
          },
          solver: "repulsion", // options: "barnesHut", "repulsion", "hierarchicalRepulsion", forceAtlas2Based
        },
      };

      const network = new Network(
        networkContainer.current,
        newData,
        newOptions
      );
      networkInstance.current = network;

      network.on("click", (params) => {
        if (params.nodes.length === 0) {
          // setTimeout(() => {
          //   network.setData(initialData);
          // }, 10);
          return;
        }

        const selectedNodeId = params.nodes[0];
        const childNodes = filterChildNodes(selectedNodeId);

        const mainNodelabel = nodesData.find(
          (node) => node.id === selectedNodeId
        )?.label;
        const childeNodelabel = thirdNodesData.find(
          (node) => node.id === selectedNodeId
        )?.label;

        const selectedNode = {
          id: selectedNodeId,
          label: mainNodelabel || childeNodelabel,
          color: centerColor,
          size: mainNodelabel ? 12 : 26,
        };

        let isEndNode = true;
        if (childNodes.length > 0) {
          isEndNode = false;
        }

        if (isEndNode) {
          const selectedNodeLabel = getSelectedEndNodeLabel(selectedNodeId);
          setSelectedKeyword(selectedNodeLabel);
        }

        if (childNodes.length < 2) return;

        const newData = {
          nodes: new DataSet([selectedNode, ...childNodes]),
          edges: new DataSet([...childEdgesData, ...keywordEdgesData]),
        };

        setTimeout(() => {
          network.setData(newData);
          network.setOptions({
            nodes: {
              opacity: 1,
            },
            physics: {
              barnesHut: {
                springConstant: 0.01,
                springLength: 150,
                avoidOverlap: 0.7,
                centralGravity: 0.1,
              },
              solver: "barnesHut",
            },
          });
        }, 10);
      });

      network.on("hoverNode", (params) => {
        const networkCanvas = document.getElementsByTagName("canvas")[0];
        networkCanvas.style.cursor = "pointer";
      });

      network.on("blurNode", (params) => {
        const networkCanvas = document.getElementsByTagName("canvas")[0];
        networkCanvas.style.cursor = "default";
      });
    }
  }, [clickHomeButton]);

  return <GraphContainer ref={networkContainer} />;
};

export default NetworkGraph;

const GraphContainer = styled.div`
  width: 100%;
  height: 96%;
`;
