import { Node } from "vis-network";

const randomColor = () => {
  const colorOption = ["#5F84FF", "#5FB4FF"];
  return colorOption[Math.floor(Math.random() * colorOption.length)];
};

const getOpacity = (value: number) => {
  if (value < 10) {
    return 0.2;
  } else if (value < 30) {
    return 0.4;
  } else if (value < 60) {
    return 0.6;
  } else if (value < 100) {
    return 0.8;
  } else {
    return 1;
  }
};

export const centerColor = "#FF4E1B";

export const nodesData: Node[] = [
  {
    id: 1,
    label: "보건복지공통교육",
    size: 12,
    color: "#FF4E1B",
  },
  {
    id: 2,
    label: "사회복지교육",
    size: 12,
    color: "#FF4E1B",
  },
  {
    id: 3,
    label: "보건교육",
    size: 12,
    color: "#FF4E1B",
  },
  {
    id: 4,
    label: "바이오헬스교육",
    size: 12,
    color: "#FF4E1B",
  },
  {
    id: 5,
    label: "국제협력교육",
    size: 12,
    color: "#FF4E1B",
  },
  {
    id: 11,
    label: "기본역량",
    color: randomColor(),
    opacity: 5 * 0.12,
    value: 5,
  },
  {
    id: 12,
    label: "공통역량",
    color: randomColor(),
    opacity: 5 * 0.12,
    value: 5,
  },
  {
    id: 13,
    label: "디지털",
    color: randomColor(),
    opacity: 4 * 0.12,
    value: 4,
  },
  {
    id: 14,
    label: "인문교양",
    color: randomColor(),
    opacity: 7 * 0.12,
    value: 7,
  },

  // 사회복지교육(id:2) 중분류
  {
    id: 21,
    label: "복지일반",
    color: randomColor(),
    opacity: 4 * 0.12,
    value: 4,
  },
  {
    id: 22,
    label: "복지행정",
    color: randomColor(),
    opacity: 4 * 0.12,
    value: 4,
  },
  {
    id: 23,
    label: "사회서비스",
    color: randomColor(),
    opacity: 2 * 0.12,
    value: 2,
  },
  {
    id: 24,
    label: "노인복지",
    color: randomColor(),
    opacity: 4 * 0.12,
    value: 4,
  },
  {
    id: 25,
    label: "장애인복지",
    color: randomColor(),
    opacity: 2 * 0.12,
    value: 2,
  },
  {
    id: 26,
    label: "아동복지",
    color: randomColor(),
    opacity: 6 * 0.12,
    value: 6,
  },

  // 보건교육(id:3) 중분류
  {
    id: 31,
    label: "보건일반",
    color: randomColor(),
    opacity: 3 * 0.12,
    value: 3,
  },
  {
    id: 32,
    label: "건강증진",
    color: randomColor(),
    opacity: 5 * 0.12,
    value: 5,
  },
  {
    id: 33,
    label: "정신건강",
    color: randomColor(),
    opacity: 4 * 0.12,
    value: 4,
  },
  {
    id: 34,
    label: "감염병일반",
    color: randomColor(),
    opacity: 2 * 0.12,
    value: 2,
  },
  {
    id: 35,
    label: "감염병진단분석·생물안전",
    color: randomColor(),
    opacity: 3 * 0.12,
    value: 3,
  },
  {
    id: 36,
    label: "식의약일반",
    color: randomColor(),
    opacity: 2 * 0.12,
    value: 2,
  },
  {
    id: 37,
    label: "식품",
    color: randomColor(),
    opacity: 2 * 0.12,
    value: 2,
  },
  {
    id: 38,
    label: "의약품안전",
    color: randomColor(),
    opacity: 2 * 0.12,
    value: 2,
  },

  // 바이오헬스교육(id:4) 중분류
  {
    id: 42,
    label: "의료서비스",
    color: randomColor(),
    opacity: 1 * 0.12,
    value: 1,
  },
  {
    id: 43,
    label: "제약",
    color: randomColor(),
    opacity: 7 * 0.12,
    value: 7,
  },
  {
    id: 44,
    label: "화장품",
    color: randomColor(),
    opacity: 7 * 0.12,
    value: 7,
  },
  {
    id: 45,
    label: "의료기기",
    color: randomColor(),
    opacity: 7 * 0.12,
    value: 7,
  },
  {
    id: 46,
    label: "보건의료데이터 AI",
    color: randomColor(),
    opacity: 2 * 0.12,
  },

  // 국제협력교육(id:5) 중분류
  {
    id: 51,
    label: "국제개발협력(ODA)",
    color: randomColor(),
    opacity: 5 * 0.12,
    value: 5,
  },
  {
    id: 52,
    label: "글로벌헬스케어",
    color: randomColor(),
    opacity: 4 * 0.12,
    value: 4,
  },
];

export const childNodesData: Node[] = [
  {
    id: 111,
    label: "신규자",
    color: randomColor(),
    opacity: getOpacity(2),
    value: 2,
  },
  {
    id: 121,
    label: "직무역량",
    color: randomColor(),
    opacity: getOpacity(31),
    value: 31,
  },
  {
    id: 122,
    label: "관계역량",
    color: randomColor(),
    opacity: getOpacity(6),
    value: 6,
  },
  {
    id: 123,
    label: "소진예방",
    color: randomColor(),
    opacity: getOpacity(7),
    value: 7,
  },
  {
    id: 124,
    label: "리더십",
    color: randomColor(),
    opacity: getOpacity(4),
    value: 4,
  },
  {
    id: 131,
    label: "OA실무",
    color: randomColor(),
    opacity: getOpacity(9),
    value: 9,
  },
  {
    id: 141,
    label: "인문학",
    color: randomColor(),
    opacity: getOpacity(5),
    value: 5,
  },
  {
    id: 142,
    label: "건강관리",
    color: randomColor(),
    opacity: getOpacity(8),
    value: 8,
  },
  {
    id: 143,
    label: "역사",
    color: randomColor(),
    opacity: getOpacity(3),
    value: 3,
  },
  {
    id: 241,
    label: "노인복지일반",
    color: randomColor(),
    opacity: 13 * 0.12,
    value: 13,
  },
  {
    id: 242,
    label: "노인의료돌봄통합지원",
    color: randomColor(),
    opacity: getOpacity(19),
    value: 19,
  },
  {
    id: 243,
    label: "노인인권",
    color: randomColor(),
    opacity: getOpacity(9),
    value: 9,
  },
  {
    id: 244,
    label: "장기요양",
    color: randomColor(),
    opacity: getOpacity(7),
    value: 7,
  },
  {
    id: 211,
    label: "사회복지법제",
    color: randomColor(),
    opacity: getOpacity(1),
    value: 1,
  },
  {
    id: 212,
    label: "사회복지정책",
    color: randomColor(),
    opacity: getOpacity(15),
    value: 15,
  },
  {
    id: 213,
    label: "사회복지 가치와 인권",
    color: randomColor(),
    opacity: getOpacity(12),
    value: 12,
  },
  {
    id: 214,
    label: "사업관리",
    color: randomColor(),
    opacity: getOpacity(8),
    value: 8,
  },
  {
    id: 221,
    label: "공공부조",
    color: randomColor(),
    opacity: getOpacity(9),
    value: 9,
  },
  {
    id: 222,
    label: "지역복지",
    color: randomColor(),
    opacity: 24 * 0.12,
    value: 24,
  },
  {
    id: 223,
    label: "통합사례관리",
    color: randomColor(),
    opacity: getOpacity(12),
    value: 12,
  },
  {
    id: 231,
    label: "복지부정수급예방",
    color: randomColor(),
    opacity: getOpacity(1),
    value: 1,
  },
  {
    id: 232,
    label: "사회복지법인및시설관리",
    color: randomColor(),
    opacity: getOpacity(74),
    value: 74,
  },
  {
    id: 261,
    label: "아동권리",
    color: randomColor(),
    opacity: getOpacity(4),
    value: 4,
  },
  {
    id: 262,
    label: "아동돌봄",
    color: randomColor(),
    opacity: getOpacity(5),
    value: 5,
  },
  {
    id: 263,
    label: "아동보호",
    color: randomColor(),
    opacity: getOpacity(1),
    value: 1,
  },
  {
    id: 264,
    label: "아동안전·건강",
    color: randomColor(),
    opacity: getOpacity(7),
    value: 7,
  },
  {
    id: 265,
    label: "아동청년복지일반",
    color: randomColor(),
    opacity: getOpacity(5),
    value: 5,
  },
  {
    id: 251,
    label: "장애인복지일반",
    color: randomColor(),
    opacity: getOpacity(178),
    value: 178,
  },
  {
    id: 252,
    label: "장애인인권",
    color: randomColor(),
    opacity: getOpacity(5),
    value: 5,
  },
  {
    id: 341,
    label: "감염병 위기대응",
    color: randomColor(),
    opacity: getOpacity(2),
    value: 2,
  },
  {
    id: 342,
    label: "감염병 정책·관리예방",
    color: randomColor(),
    opacity: getOpacity(38),
    value: 38,
  },
  {
    id: 351,
    label: "의료감염관리",
    color: randomColor(),
    opacity: getOpacity(18),
    value: 18,
  },
  {
    id: 352,
    label: "생물안전",
    color: randomColor(),
    opacity: getOpacity(4),
    value: 4,
  },
  {
    id: 321,
    label: "건강생활 실천",
    color: randomColor(),
    opacity: getOpacity(13),
    value: 13,
  },
  {
    id: 322,
    label: "디지털헬스",
    color: randomColor(),
    opacity: getOpacity(2),
    value: 2,
  },
  {
    id: 323,
    label: "비감염성질환예방관리",
    color: randomColor(),
    opacity: getOpacity(24),
    value: 24,
  },
  {
    id: 324,
    label: "인구집단별 건강관리",
    color: randomColor(),
    opacity: getOpacity(7),
    value: 7,
  },
  {
    id: 325,
    label: "지역사회 건강증진",
    color: randomColor(),
    opacity: getOpacity(162),
    value: 162,
  },
  {
    id: 326,
    label: "건강생활 실천",
    color: randomColor(),
    opacity: getOpacity(2),
    value: 2,
  },
  {
    id: 311,
    label: "보건의료 정책",
    color: randomColor(),
    opacity: getOpacity(6),
    value: 6,
  },
  {
    id: 312,
    label: "의료급여",
    color: randomColor(),
    opacity: getOpacity(1),
    value: 1,
  },
  {
    id: 313,
    label: "지역보건",
    color: randomColor(),
    opacity: getOpacity(2),
    value: 2,
  },
  {
    id: 314,
    label: "치매관리",
    color: randomColor(),
    opacity: getOpacity(2),
    value: 2,
  },
  {
    id: 361,
    label: "식의약정책",
    color: randomColor(),
    opacity: getOpacity(1),
    value: 1,
  },
  {
    id: 371,
    label: "식품안전",
    color: randomColor(),
    opacity: getOpacity(1),
    value: 1,
  },
  {
    id: 381,
    label: "의약품",
    color: randomColor(),
    opacity: getOpacity(5),
    value: 5,
  },
  {
    id: 331,
    label: "중독",
    color: randomColor(),
    opacity: getOpacity(1),
    value: 1,
  },
  {
    id: 332,
    label: "자살예방·생명존중",
    color: randomColor(),
    opacity: getOpacity(3),
    value: 3,
  },
  {
    id: 333,
    label: "정신인권",
    color: randomColor(),
    opacity: getOpacity(14),
    value: 14,
  },
  {
    id: 334,
    label: "지역사회 정신건강",
    color: randomColor(),
    opacity: getOpacity(9),
    value: 9,
  },
  {
    id: 461,
    label: "보건의료데이터·AI",
    color: randomColor(),
    opacity: getOpacity(1),
    value: 1,
  },
  {
    id: 451,
    label: "마케팅",
    color: randomColor(),
    opacity: getOpacity(1),
    value: 1,
  },
  {
    id: 452,
    label: "생산",
    color: randomColor(),
    opacity: getOpacity(1),
    value: 1,
  },
  {
    id: 453,
    label: "인허가",
    color: randomColor(),
    opacity: getOpacity(3),
    value: 3,
  },
  {
    id: 421,
    label: "의료서비스일반",
    color: randomColor(),
    opacity: getOpacity(1),
    value: 1,
  },
  {
    id: 431,
    label: "마케팅",
    color: randomColor(),
    opacity: getOpacity(1),
    value: 1,
  },
  {
    id: 432,
    label: "생산",
    color: randomColor(),
    opacity: getOpacity(4),
    value: 4,
  },
  {
    id: 433,
    label: "연구개발",
    color: randomColor(),
    opacity: getOpacity(6),
    value: 6,
  },
  {
    id: 434,
    label: "인허가",
    color: randomColor(),
    opacity: getOpacity(2),
    value: 2,
  },
  {
    id: 435,
    label: "품질",
    color: randomColor(),
    opacity: getOpacity(1),
    value: 1,
  },
  {
    id: 441,
    label: "마케팅",
    color: randomColor(),
    opacity: getOpacity(1),
    value: 1,
  },
  {
    id: 442,
    label: "생산",
    color: randomColor(),
    opacity: getOpacity(1),
    value: 1,
  },
  {
    id: 443,
    label: "연구개발",
    color: randomColor(),
    opacity: getOpacity(2),
    value: 2,
  },
  {
    id: 444,
    label: "인허가",
    color: randomColor(),
    opacity: getOpacity(2),
    value: 2,
  },
  {
    id: 511,
    label: "감염병",
    color: randomColor(),
    opacity: getOpacity(26),
    value: 26,
  },
  {
    id: 512,
    label: "국제개발협력",
    color: randomColor(),
    opacity: getOpacity(9),
    value: 9,
  },
  {
    id: 513,
    label: "일차보건의료",
    color: randomColor(),
    opacity: getOpacity(2),
    value: 2,
  },
  {
    id: 521,
    label: "의료통역",
    color: randomColor(),
    opacity: getOpacity(2),
    value: 2,
  },
  {
    id: 522,
    label: "의료해외진출",
    color: randomColor(),
    opacity: getOpacity(38),
    value: 38,
  },
];

export const thirdNodesData: Node[] = childNodesData.map((node) => ({
  ...node,
  opacity: 1,
  font: {
    size: 16,
  },
}));

const categories_with_selected_keywords = require("../data/categories_with_selected_keywords.json");

export const keywordNodesData: Node[] = Object.entries(
  categories_with_selected_keywords
).flatMap(([level1Key, level1Value]: any) => {
  // level1의 id 찾기 (1~5)
  const level1Id = nodesData.find((node) => node.label === level1Key)?.id;

  return Object.entries(level1Value).flatMap(
    ([level2Key, level2Value]: any) => {
      // level2의 id 찾기 (11~52)
      const level2Node = nodesData.find(
        (node) =>
          node.label === level2Key &&
          Math.floor(Number(node.id) / 10) === level1Id
      );

      return Object.entries(level2Value).flatMap(
        ([level3Key, keywords]: any) => {
          // level3의 id 찾기 (111~522)
          const level3Id = childNodesData.find(
            (node) =>
              node.label === level3Key &&
              Math.floor(Number(node.id) / 10) === level2Node?.id
          )?.id;

          // keywords를 노드로 변환
          return keywords.map((keyword: string, index: number) => ({
            id: `${level3Id}_${index + 1}`,
            label: keyword,
            color: "#5FE4FF99",
            opacity: 0.3,
            size: 12,
          }));
        }
      );
    }
  );
});
